import aboutMe from "../assets/images/aboutme.png";
import ReturnHomeButton from "../components/UI/ReturnHomeButton";
import PhotoSummary from "../components/aboutme/photo";
import Summary from "../components/aboutme/summary";
import Footer from "../components/homepage/Footer";

export default function AboutMe() {
  return (
    <div className="container mx-auto">
      <img src={aboutMe} alt="About Me" className="w-full pt-64" />
      <Summary />
      <PhotoSummary />
      <ReturnHomeButton />
      <Footer />
    </div>
  );
}
