import { useEffect, useRef } from "react";
import profilePhoto from "../../assets/images/farischew.JPG";
import VanillaTilt from "vanilla-tilt";

import classes from "./photo.module.css";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

export default function PhotoSummary() {
  const options = {
    scale: 1,
    speed: 500,
    max: 30,
  };

  return (
    <div className="flex mt-32">
      <div className="w-1/2">
        <h1 className="text-white text-3xl mb-4">Interests:</h1>
        <ul className="flex flex-wrap gap-3">
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Football</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Film Photography</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Machine Learning</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>UI/UX Design</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>User Accessibility</p>
          </li>
        </ul>

        <h1 className="text-white text-3xl mb-4 mt-6">Soft Skills:</h1>
        <ul className="flex flex-wrap gap-3">
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Communication & Teamwork</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Initiative</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Client Relations</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Project Management</p>
          </li>
          <li className="border border-gray px-6 py-3 rounded-lg text-white text-xl shadow-md">
            <p>Team Leading</p>
          </li>
        </ul>
      </div>
      <div className="w-1/2">
        <Tilt className={classes.card} options={options}>
          <img src={profilePhoto} alt="" />
        </Tilt>
      </div>
    </div>
  );
}
