export default function ProjectItem({ project }) {
  return (
    <a href={project.link} target="_blank" rel="noreferrer">
      <li>
        <div className="flex align-center items-center gap-4 pb-10 border-b-2 border-white mt-16">
          <img
            src={project.image}
            alt={project.title}
            className="flex-none w-96 h-96 bg-white rounded-3xl "
          />
          <div className="text-gray grow">
            <h1 className="text-3xl underline underline-offset-4">
              {project.title}
            </h1>
            <h3 className="text-xl">{project.description}</h3>
            <ul className="flex flex-wrap gap-3 mt-8">
              {project.tags.map((tag) => (
                <li className="border border-gray px-6 py-2 rounded-lg text-white text-lg shadow-md">
                  <p>{tag}</p>
                </li>
              ))}
            </ul>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-16 h-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </li>
    </a>
  );
}
