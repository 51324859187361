export const TECHSTACK = [
  {
    title: "HTML",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
    description:
      "HTML, or Hypertext Markup Language, is a markup language for the web that defines the structure of web pages.",
    tags: ["FRONT END"],
  },
  {
    title: "CSS",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
    description:
      "Cascading Style Sheets (CSS) is a stylesheet language used to describe the presentation of a document written in HTML or XML.",
    tags: ["FRONT END"],
  },
  {
    title: "Bootstrap",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg",
    description:
      "Bootstrap is a free, open source front-end development framework for the creation of websites and web apps.",
    tags: ["FRONT END"],
  },
  {
    title: "JavaScript",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    description:
      "JavaScript is a scripting or programming language that allows you to implement complex features on web pages",
    tags: ["FRONT END"],
  },
  {
    title: "React",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    description:
      "React is a library for building composable user interfaces. It encourages the creation of reusable UI components, which present data that changes over time.",
    tags: ["FRONT END"],
  },
  {
    title: "TypeScript",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
    description:
      "TypeScript extends JavaScript by adding data types, classes, and other object-oriented features with type-checking. It is a typed superset of JavaScript that compiles to plain JavaScript.",
    tags: ["FRONT END"],
  },
  {
    title: "Ruby",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ruby/ruby-original.svg",
    description:
      "Ruby is a popular general-purpose object-oriented programming (OOP) language that focuses on simplicity and productivity.",
    tags: ["LANGUAGE"],
  },
  {
    title: "Rails",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rails/rails-original-wordmark.svg",
    description:
      "Rails is a model–view–controller (MVC) framework, providing default structures for a database, a web service, and web pages.",
    tags: ["FRAMEWORK"],
  },
  {
    title: "Next.JS",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
    description:
      "Next.js is a React framework that gives you building blocks to create web applications. ",
    tags: ["FRAMEWORK"],
  },
  {
    title: "MySQl",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg",
    description:
      "MySQL is a relational database management system (RDBMS) developed by Oracle that is based on structured query language (SQL). ",
    tags: ["DATABASE"],
  },
  {
    title: "sqlite",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sqlite/sqlite-original.svg",
    description:
      "SQLite is an in-process library that implements a self-contained, serverless, zero-configuration, transactional SQL database engine.",
    tags: ["DATABASE"],
  },
];
