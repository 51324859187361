export default function ContactForm() {
  return (
    <div className="pt-32">
      <form className="w-4/6 mx-auto border border-gray p-4 rounded-3xl shadow-md shadow-gray bg-base">
        <div>
          <h1 className="text-center text-gray text-md pb-4">
            New message for Faris
          </h1>
        </div>
        <div className="flex justify-center gap-4">
          <div className="relative rounded-md shadow-sm w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">From:</span>
            </div>
            <input
              type="text"
              name="price"
              id="price"
              className="block w-full rounded-lg border-0 py-2.5 pl-16 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="josh@gmail.com"
            />
          </div>
          <div className="relative rounded-lg shadow-sm w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">To:</span>
            </div>
            <span className="block w-full rounded-lg border-0 py-2.5 pl-16 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white">
              farischew@gmail.com
            </span>
          </div>
        </div>
        <div className="w-full pt-3">
          <div className="flex justify-center gap-4">
            <div className="relative rounded-md shadow-sm w-full">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">Topic:</span>
              </div>
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="block w-full rounded-lg border-0 py-3.5 pl-16 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option>I've got a cool collaboration idea!</option>
                <option>Job Opportunities</option>
                <option>Questions about your projects</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-4">
            <textarea
              id="about"
              name="about"
              rows={10}
              placeholder="Write your message here..."
              className="block w-full rounded-lg border-0 py-3.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={""}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
