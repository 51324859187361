import Banner from "../components/homepage/Banner";
import Cards from "../components/homepage/Cards";
import Footer from "../components/homepage/Footer";

import footerName from "../assets/images/footername.png";
import { useEffect, useRef } from "react";

export default function Homepage() {
  const heroRef = useRef(null);

  useEffect(() => {
    const updateMousePosition = (event) => {
      if (!updateMousePosition) return;
      const { clientX, clientY } = event;
      heroRef.current.style.setProperty("--x", `${clientX}px`);
      heroRef.current.style.setProperty("--y", `${clientY}px`);
    };

    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return (
    <div>
      <style jsx>{`
        .hero {
          background-image: radial-gradient(
            circle farthest-side at var(--x, 100px) var(--y, 100px),
            #1250aa 0%,
            transparent 100%
          );
        }

        .title {
          font-size: 80px;
          text-transform: uppercase;
          transform: rotate(10deg) translateX(calc(var(--x) / 10, 0px));
        }
      `}</style>
      <div ref={heroRef} className="hero">
        <Banner />
        <Cards />
        <div className="h-screen">
          <img
            src={footerName}
            alt="Faris Chew"
            className="object-fill w-full pt-64"
          />
          <Footer />
        </div>
      </div>
    </div>
  );
}
