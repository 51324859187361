import projects from "../assets/images/projects.png";
import ReturnHomeButton from "../components/UI/ReturnHomeButton";
import Footer from "../components/homepage/Footer";
import ProjectItem from "../components/projects/projectitem";

import { PROJECTS } from "../constants/projects";

export default function Projects() {
  return (
    <div className="container mx-auto">
      <img src={projects} alt="projects" className="w-full pt-64" />
      <div>
        <ul>
          {PROJECTS.map((project) => (
            <ProjectItem project={project} />
          ))}
        </ul>
      </div>
      <ReturnHomeButton />
      <Footer />
    </div>
  );
}
