import { useEffect, useRef, useState } from "react";
import classes from "./Cards.module.css";
import VanillaTilt from "vanilla-tilt";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import aboutMe from "../../assets/images/homepage-cards/about-me.png";
import techStacks from "../../assets/images/homepage-cards/tech-stacks.png";
import projects from "../../assets/images/homepage-cards/projects.png";
import contact from "../../assets/images/homepage-cards/contact.png";
import location from "../../assets/images/homepage-cards/Location.png";
import { useScroll, useTransform, motion } from "framer-motion";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

export default function Cards() {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.7]);

  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    // Function to update the current time
    const updateCurrentTime = () => {
      const currentTimeNow = new Intl.DateTimeFormat("en-US", {
        timezone: "Asia/Singapore",
        timeStyle: "medium",
        hour12: false,
      }).format(new Date());
      setCurrentTime(currentTimeNow);
    };

    // Update the current time every second
    const interval = setInterval(updateCurrentTime, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures the effect runs only once

  const options = {
    scale: 1,
    speed: 500,
    max: 30,
  };

  return (
    <motion.div
      ref={targetRef}
      style={{ opacity, scale }}
      className="flex relative h-[120vh] justify-center mt-32 pb-16 "
    >
      <ul className={classes.wrapper}>
        <div className={classes.mapdiv}>
          <li>
            <Tilt className={classes.map} options={options}>
              <div className={classes.content}>
                <div>
                  <img src={location} alt="Location in Singapore"></img>
                </div>
              </div>
              <div className="absolute bottom-40 flex flex-col justify-center align-middle">
                <h1 className="text-white text-3xl text-center pt-4">
                  Singapore
                </h1>
                <h3 className="text-white text-sm text-center pt-4">
                  {currentTime}
                </h3>
              </div>
            </Tilt>
          </li>
          <div className={classes.besidemap}>
            <Link to={ROUTES.ABOUT_ME}>
              <li>
                <Tilt className={classes.aboutme} options={options}>
                  <div className={classes.content}>
                    <div>
                      <img src={aboutMe} alt="About Me"></img>
                    </div>
                  </div>
                </Tilt>
              </li>
            </Link>
            <Link to={ROUTES.TECH_STACK}>
              <li>
                <Tilt className={classes.techstack} options={options}>
                  <div className={classes.content}>
                    <div>
                      <img src={techStacks} alt="Tech Stacks"></img>
                    </div>
                  </div>
                </Tilt>
              </li>
            </Link>
            <Link to={ROUTES.PROJECTS}>
              <li>
                <Tilt className={classes.projects} options={options}>
                  <div className={classes.content}>
                    <div>
                      <img src={projects} alt="Projects"></img>
                    </div>
                  </div>
                </Tilt>
              </li>
            </Link>
            <Link to={ROUTES.CONTACT}>
              <li>
                <Tilt className={classes.sayhello} options={options}>
                  <div className={classes.content}>
                    <div>
                      <img src={contact} alt="Contact me"></img>
                    </div>
                  </div>
                </Tilt>
              </li>
            </Link>
          </div>
        </div>
        {/* <div className={classes.belowmap}>
          <li>
            <Tilt className={classes.music} options={options}>
              <div className={classes.content}>
                <div className={classes.header}>
                  <h1>Music</h1>
                  <p>What I'm Listening to Right Now</p>
                </div>
                <div className="bg-white h-full w-full mx-auto"></div>
              </div>
            </Tilt>
          </li>
        </div> */}
      </ul>
    </motion.div>
  );
}
