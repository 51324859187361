import ContactForm from "../components/contact/contactform";
import Footer from "../components/homepage/Footer";

import background from "../assets/images/background.jpg";
import ReturnHomeButton from "../components/UI/ReturnHomeButton";

export default function Contact() {
  return (
    <div>
      <div
        className="flex w-screen h-screen justify-center content-center items-center"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto z-50">
          <ContactForm />
          <ReturnHomeButton />
          <Footer />
        </div>
      </div>
    </div>
  );
}
