import classes from "./Card.module.css";

export default function Card(techstack) {
  const tech = techstack.techstack;

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <img src={tech.img} alt={tech.title} />
        <h1>{tech.title}</h1>
      </div>
      <div className={classes.content}>
        <p>{tech.description}</p>
        <ul>
          {tech.tags.map((tag) => (
            <li>{tag}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
