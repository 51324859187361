import soundstate from "../assets/images/soundstate.png";
import reactagram from "../assets/images/reactagram.png";

export const PROJECTS = [
  {
    title: "SoundState",
    description:
      "Utilizing the power of AI, SoundState is a smart playlist generator with AI image generation to make sharing and discovering new music with friends fun again.",
    tags: [
      "Ruby on Rails",
      "HTML",
      "CSS",
      "JavaScript",
      "PostgreSQL",
      "Heroku",
    ],
    link: "https://www.soundstate.live/",
    image: soundstate,
  },
  {
    title: "Reactagram",
    description:
      "A conceptual mockup of Instagram, a social media platform where users can upload photos and interact with friends.",
    tags: ["React", "Tailwind", "Firebase", "Vercel"],
    link: "https://reactagram-gray.vercel.app/",
    image: reactagram,
  },
];
