import { useScroll, useTransform, motion } from "framer-motion";
import { useRef } from "react";

export default function Banner() {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.7]);
  const position = useTransform(scrollYProgress, (pos) => {
    return pos === 1 ? "relative" : "fixed";
  });

  return (
    <motion.section
      style={{ opacity }}
      ref={targetRef}
      className="flex w-screen h-screen justify-center content-center items-center scroll-smooth overflow-scroll"
    >
      <motion.div
        style={{ scale, position }}
        className="flex flex-col gap-8 w-2/5 h-1/2 justify-center content-center items-center fixed"
      >
        <h1 className="mb-12 text-center text-white font-heading text-7xl leading-[1]">
          Hi 👋🏽
          <br />
          <div className="flex">
            I'm Faris Chew
            <h1 className=" text-green font-heading text-7xl leading-[1]">.</h1>
          </div>
        </h1>

        <p className="text-3xl font-heading text-center text-white">
          Welcome to my little corner of the internet! I'm an aspiring
          full-stack developer - working on projects to bring my ideas to life.
          Learn more about me and some of my work here.
        </p>

        <p className="text-2xl font-heading text-center text-white">
          Do feel free to connect with me on any of my social media links below.
        </p>
      </motion.div>
    </motion.section>
  );
}
