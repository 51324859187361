import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function Footer() {
  return (
    <div className="flex flex-col justify-center align-middle items-center content-center pt-32">
      <div className="flex gap-4 text-white text-2xl pb-32">
        <Link to={ROUTES.ABOUT_ME}>
          <p>About me</p>
        </Link>
        <p> | </p>
        <Link to={ROUTES.PROJECTS}>
          <p>Projects</p>
        </Link>
        <p> | </p>
        <Link to={ROUTES.CONTACT}>
          <p>Contact</p>
        </Link>
      </div>
    </div>
  );
}
