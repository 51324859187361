import { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import * as ROUTES from "./constants/routes";
import Homepage from "./pages/homepage";
import AboutMe from "./pages/about-me";
import Projects from "./pages/projects";
import Contact from "./pages/contact";
import TechStack from "./pages/tech-stack";

function App() {
  return (
    <div className={`min-h-screen bg-background-dark`}>
      <Router>
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route path={ROUTES.HOMEPAGE} element={<Homepage />} />
            <Route path={ROUTES.ABOUT_ME} element={<AboutMe />} />
            <Route path={ROUTES.PROJECTS} element={<Projects />} />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
            <Route path={ROUTES.TECH_STACK} element={<TechStack />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
