import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function ReturnHomeButton() {
  return (
    <div className="pt-16">
      <Link
        to={ROUTES.HOMEPAGE}
        className="mx-2.5 m-auto flex justify-center justify-items-center align-middle"
      >
        <button className="bg-gradient-to-r from-graylight to-gray p-4 rounded-lg w-1/2 h-32 font-medium text-2xl shadow-lg shadow-gray">
          Return Home
        </button>
      </Link>
    </div>
  );
}
