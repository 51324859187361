export default function Summary() {
  return (
    <div className="mt-16">
      <div className="flex px-4 gap-16">
        <div className="w-1/2">
          <p className="text-white text-2xl leading-loose text-justify ">
            I graduated Singapore Management University with a degree in
            Business Management, majoring in Corporate Communication and
            Strategic Management.
            <br />
            <br />
            In the last year of my studies, I took a course in Machine Learning
            for Design and Communications - partly out of interest and partly to
            challenge myself. At the end of the course, I fell in love with the
            world of tech. This spurred me to take a leap of faith and plunge
            head-first into all things computer science.
            <br />
            <br />
            As a self-starter in this field, I enrolled myself in Le Wagon's
            9-week Web Development bootcamp where I had the pleasure of meeting
            like-minded individuals and to further challenge myself with new
            programming languages and concepts, such as HTML, CSS, JavaScript
            and Ruby on Rails.
          </p>
        </div>
        <div className="w-1/2">
          <p className="text-white text-2xl leading-loose text-justify ">
            The course was intense and demanding - with the climax of our
            experience inculcated into a 2-week project sprint where we had to
            built a fully-functional web-app in groups of four (check out what
            my team and I achieved in my projects segment).
            <br />
            <br />
            After the course, I continued my self-development into the field -
            learning React and Java with Udemy Courses. In my spare time, I'm
            either watching football or working on side projects to better my
            skills.
            <br />
            <br />
            I'm currently based in Singapore. If you have any great ideas or job
            opportunities, do feel free to reach out!
          </p>
        </div>
      </div>
    </div>
  );
}
