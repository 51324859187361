import techstack from "../assets/images/techstack.png";
import ReturnHomeButton from "../components/UI/ReturnHomeButton";
import Footer from "../components/homepage/Footer";
import Card from "../components/techstack/Card";

import { TECHSTACK } from "../constants/techstacks";

export default function TechStack() {
  return (
    <div className="container mx-auto">
      <img src={techstack} alt="projects" className="w-full pt-64" />
      <div className="pt-32">
        <ul className="grid grid-cols-3 gap-16 px-12">
          {TECHSTACK.map((techstack) => (
            <li>
              <Card techstack={techstack} />
            </li>
          ))}
        </ul>
      </div>
      <ReturnHomeButton />
      <Footer />
    </div>
  );
}
